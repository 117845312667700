import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CardModule from "../../Home/CardModule/CardModule";
import "./HyrjeTest.scss";
import Loader from "../../../shared/Loader/Loader";

function HyrjeTest({ updateQuizStep }) {
  const { moduleId } = useParams();
  const [title , setTitle] = useState('');
  const [ description, setDescription ] = useState('');
  const [loading, setLoading] = useState(false); 


  const handleDownload = () => {
    const downloadLink = `/assets/Stch-M${title}.pdf`;
    const anchor = document.createElement("a");
    anchor.href = downloadLink;
    anchor.download = `Stch-M${title}.pdf`; 
    anchor.click();
  };

  function checkModuleId(id){
    switch(id){
      case 'moduli-1':
        setTitle('1');
        setDescription('Përkufizimi, qëllimi i trajnimit, dhe përfitimet. Parimet e arsimit gjithëpërfshirës, nivelet e gjithëpërfshirjes, dhe korniza ligjore në Kosovë.');
      break;
      case 'moduli-2':
        setTitle('2');
        setDescription('Modeli biopsikosocial i Klasifikimit Ndërkombëtar të Funksionimit, aftësisë së kufizuar dhe shëndetit (KNF).');
      break;
      case 'moduli-3':
        setTitle('3');
        setDescription('Formulari i raportimit nga shkolla dhe Raporti i monitorimit.');
      break;
      default: 
        setTitle('');
    }
  }

  useEffect(() => {
    checkModuleId(moduleId);
  }, [])

  if (
    moduleId == "moduli-1" ||
    moduleId == "moduli-2" ||
    moduleId == "moduli-3"
  ) {
    return (
      <div className="hyrjetest-container container">
        {loading && <Loader />}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="card-container">
                <h3>{`PËRMBLEDHJA E MODULIT ${title}`}</h3>
                <p>Shkarko duke klikuar butonin më poshtë.</p>
                <div className="card-bottom">
                  <div className="card-btn" onClick={handleDownload}>
                    <p className="card-btn-text">Shkarko</p>
                  </div>
                  <div className="card-progress">
                    <p>{title}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="card-container" id="second-card">
                <h3>{`TESTI I MODULIT ${title}`}</h3>
                <p>{description}</p>
                <div className="card-bottom">
                  <div className="card-btn" onClick={() => updateQuizStep()} >
                    <p className="card-btn-text">Fillo</p>
                  </div>
                  <div className="card-progress">
                    <p>{title}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="row">
          <div className="buttons-container">
            <div className="btn-container" onClick={() => { window.location.reload()}}>
              <div className="left arrow-image-container"></div>
              <p className="btn-text">Kthehu</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default HyrjeTest;
