import axios from "axios";

const fullUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000/api" : process.env.REACT_APP_API;

export const api = axios.create({
  baseURL: fullUrl,
});

api.defaults.headers.post["Content-Type"] = "application/json";

// Add a request interceptor
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// api.interceptors.response.use(null, (err) => {
//   if (err.response?.status === 401) {
//     window.location.href = "/";
//   } else if (err.response?.status === 404) {
//     window.location.href = "/notfound";
//     // console.log('error');
//   }
//   return Promise.reject(err);
// }
// );
