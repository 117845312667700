import React, { useEffect, useState } from 'react'
import './NiveletArsimit.scss'
import NdaloReflekto from '../../../../shared/NdaloReflekto/NdaloReflekto'
import { useParams } from 'react-router-dom';
import MemoryGame from '../../../ModuleClassComponents/ModuleClass1/NiveletArsimit/MemoryGame/MemoryGame';
import { api } from '../../../../../api/axios';
import { dropdownData } from '../../../ModuleClassComponents/ModuleClass1/NiveletArsimit/DropdownContent/dropdownData';
import DropdownContent from '../../../ModuleClassComponents/ModuleClass1/NiveletArsimit/DropdownContent/DropdownContent';

function NiveletArsimit() {

  const [userId, setUserId] = useState(null);

  const {moduleId , moduleClass} = useParams();

  async function fetchUserData(){
    try {
      const data = await api.get('user/myDetails');
      setUserId(data.data._id)
    } catch (error) {
      console.log('Error on fetching user data', error);
    }
  }
  
  useEffect(() => {
    fetchUserData();
  }, [])

  return (
    <div className='container niveletarsimit-container'>
      <div className="col-lg-12 col-md-12 col-sm-12 niveletarsimit-top">
        <MemoryGame moduleId={moduleId} moduleClass={moduleClass} userId={userId}/>
        <div className="col-12 nivelet-img-container">
          <div className="nivelet-img"></div>
        </div>

        <div className="col-lg-10 col-md-12 col-sm-12 niveletarsimit-inclusive-container">
          <h3>Nivelet e arsimit për të gjithë</h3>
          <div className="niveletarsimit-inclusive-subcontainer">
            <p>Nivelet e arsimit për të gjithë janë të vlerësuar dhe konsiderohen si një drejtësi themelore
              dhe nevojë e çdo individi. Arsimi është një proces i gjatë dhe kompleks që ka për qëllim të ofrojë njohuri,
              aftësi dhe shkathtësi të nevojshme për të funksionuar në shoqëri.
            </p>
            <br />
            <p><span>Mohimi</span> i arsimit për të gjithë është një shkelje e të drejtave themelore të njeriut. Kur dikush mohon 
              ose kufizon qasjen e individëve në arsim, shkel ligjet dhe standardet ndërkombëtare të të drejtave të njeriut. Një mohim 
              i tillë mund të ndodhë për shkaqe të ndryshme, përfshirë diskriminimin, mungesën e burimeve, konfliktet ose vështirësitë ekonomike.
            </p>
            <br />
            <p><span>Pranimi</span> i arsimit për të gjithë do të thotë të sigurohet qasje në arsim për të gjithë individët, pa kufizime ose diskriminim.
              Kjo përfshin të gjithë grupet e shoqërisë, duke përfshirë të varfërit, minoritetet etnike, fëmijët me aftësi të kufizuara dhe të tjerët të 
              cilët janë në rrezik të mohohen nga arsimimi.
            </p>
            <br />
            <p><span>Mirëkuptimi</span> dhe respekti ndaj diversitetit janë thelbësore për të siguruar arsim për të gjithë. Një sistem arsimor i drejtë dhe i 
              barabartë duhet të njohë dhe të vlerësojë ndryshimet në kulturë, gjuhë, besim, përkatësi etnike dhe nivel të ndryshëm të aftësive. Kjo do të thotë të 
              promovohet një mjedis më i hapur dhe i pranueshëm, që i përkrah dhe respekton të gjithë nxënësit.
            </p>
            <br />
            <p><span>Njohja</span> e arsimit për të gjithë është e rëndësishme për zhvillimin e një shoqërie të qëndrueshme dhe të drejtë. Arsimi ofron mundësi për rritje personale, 
              përparim profesional dhe kontribut në shoqëri. Duke njohur dhe investuar në arsimimin e të gjithë individëve, ndërtojmë një shoqëri më të përgjegjshme dhe të zhvilluar.
              Për të arritur nivelet e larta të arsimit për të gjithë, është e nevojshme të kemi politika arsimore të përshtatshme, investime të mjaftueshme në arsim, trajnime profesionale për mësuesit,
              infrastrukturë adekuate, përdorim të teknologjisë së informacionit dhe komunikimit dhe angazhim të përbashkët nga autoritetet, shoqëria civile dhe komuniteti në tërësi.
            </p>
          </div>
        </div>

        <div className="col-12 niveletarsimit-dropdowns-container">
          {dropdownData.map((data) => {
            return(
              <DropdownContent 
                key={data.id} 
                title={data.title} 
                imageSrc={data.imageSrc}
                content={data.content}
              /> 
            )
          })}
        </div>

        <div className="col-lg-10 col-md-12 col-sm-12 niveletarsimit-inclusive-subcontainer">
          <p>Në kontekstin e arsimit, "gjithëpërfshirja e nxënësve" është një koncept që promovon arsimimin dhe përgatitjen e nxënësve me aftësi të ndryshme dhe nevoja të ndryshme në të njëjtën mjedis shkollor. 
            Kjo është e kundërta e praktikave të përjashtimit dhe veçimit (segregimit) të nxënësve.
          </p>
          <br />
          <p>Qëllimi i gjithëpërfshirjes së nxënësve është të sigurojë një arsimim të cilësisë së lartë për të gjithë nxënësit, duke i përfshirë ata në mënyrë aktive në procesin mësimor dhe duke u përgatitur për pjesëmarrjen në shoqëri në të ardhmen.
             Kjo përkrah dhe promovon parimin e barazisë së mundësive për të gjithë nxënësit, duke u fokusuar në nevojat dhe aftësitë individuale të tyre.
          </p>
        </div>

        <div className="col-lg-10 col-md-12 col-sm-12 highlighted-paragraph-container">
          <p>
            Duke u bazuar në çfarë u tha më lart, arsimit gjithëpërfshirës iu mundëson <span>të gjithë fëmijëve</span> që
            <span>të mësojnë së bashku</span>, me <span>mbështetje sipas nevojave</span> të tyre individuale.
          </p>
        </div>
      </div>
      <div className="col-12 niveletarsimit-bottom">
        <h3>Arsimi gjithëpërfshirës kundrejt arsimit për nxënës me nevoja të veçanta</h3>
        <div className="niveletarsimit-table">
          <div className="niveletarsimit-table-left">
            <div className="niveletarsimit-table-header left-header">
              <p>Arsimi gjithëpërfshirës</p>
            </div>
            <div className="niveletarsimit-table-left-body">
              <div className="niveletarsimit-table-left-row">
                <p>Ndërtimi dhe shërbimet janë të dizajnuara për të plotësuar nevojat e ndryshme të të gjithë nxënësve.</p>
              </div>
              <div className="niveletarsimit-table-left-row">
                <p>Fëmijët me nevoja të veçanta mësojnë së bashku në të njëjtën klasë me nxënësit tjerë.</p>
              </div>
              <div className="niveletarsimit-table-left-row">
                <p>Strukturat, sistemi dhe kulturat i përshtaten nevojave të të gjithë nxënësve.</p>
              </div>
              <div className="niveletarsimit-table-left-row">
                <p>Ka kosto efektive pasi që struktura, sistemi dhe metodologjitë janë përshtatur për të përmbushur nevojat e ndryshme të të gjithëve nxënësve.</p>
              </div>
              <div className="niveletarsimit-table-left-row">
                <p>Promovon të drejtat dhe pjesëmarrjen e të gjithë nxënësve në shkollat e rregullta, duke përfshirë ata me nevoja të veçanta.</p>
              </div>
              <div className="niveletarsimit-table-left-row">
                <p>Të gjithë fëmijët përfitojnë nga socializimi dhe ndërveprimet me njëri-tjetrin.</p>
              </div>
              <div className="niveletarsimit-table-left-row">
                <p>Mësimi zhvillohet në shkollat me të afërta, pra afër lokacioneve të nxënësve.</p>
              </div>
              <div className="niveletarsimit-table-left-row">
                <p>Familjet dhe komunitetet mbështesin edukimin e fëmijëve që kanë nevojë për arsim special.</p>
              </div>
              <div className="niveletarsimit-table-left-row">
                <p>Ka një bashkëpunim të mirë mes shkollave, familjeve dhe komunitetit.</p>
              </div>
              <div className="niveletarsimit-table-left-row">
                <p>Mësuesit përdorin qasje që janë të përgjegjshme ndaj nevojave të ndryshme të nxënësve.</p>
              </div>
            </div>
          </div>
          <div className="niveletarsimit-table-right">
            <div className="niveletarsimit-table-header right-header">
              <p>Arsimi për nxënës me nevoja të veçanta</p>
            </div>
            <div className="niveletarsimit-table-right-body">
              <div className="niveletarsimit-table-right-row">
                <p>Udhëzimet dhe shërbimet janë të dizajnuara për të përmbushur nevojat unike të të mësuarit të nxënësit me aftësi të kufizuara.</p>
              </div>
              <div className="niveletarsimit-table-right-row">
                <p>Fëmijët me nevoja të veçanta mësojnë në klasa/ambiente të veçanta të projektuara për t'iu përshtatur nevojave të nxënësve me nevoja të veçanta.</p>
              </div>
              <div className="niveletarsimit-table-right-row">
                <p>Pritet që nxënësit me nevoja të veçanta t’i përshtaten sistemit dhe strukturave të shkollës.</p>
              </div>
              <div className="niveletarsimit-table-right-row">
                <p>Është më e kushtueshme si materiale speciale, teknikat e mësimdhënies, pajisjet, objektet dhe shërbimet përkatëse të jenë speciale: transporti, vlerësimi psikologjik,
                   këshillimi, trajtimi mjekësor, terapi fizike dhe shërbime tjera terapeutike të jenë në dispozicion në mënyrë që arsimi me nevoja të veçanta të jetë efektivë.</p>
              </div>
              <div className="niveletarsimit-table-right-row">
                <p>Është segreguese pasi ndalon fëmijët
                  me nevoja të veçanta arsimore nga
                  pjesëmarrja në disa aktivitete d.m.th
                  duke qenë në shkolla speciale, klasa të veçanta, ndërveprimi me nxënësit tjerë është i kufizuar si: lojëra dhe atletikë, etj.</p>
              </div>
              <div className="niveletarsimit-table-right-row">
                <p>Ka vetëbesim të ulët dhe të kufizuar të ndërveprimit social me nxënësit pa nevoja speciale për arsim.</p>
              </div>
              <div className="niveletarsimit-table-right-row">
                <p>Mësimi zhvillohet në shkolla speciale ose klasa speciale.</p>
              </div>
              <div className="niveletarsimit-table-right-row">
                <p>Familjet dhe komunitetet nuk janë aktiv në mbështetjen dhe edukimin e fëmijëve me nevoja të veçanta arsimore.</p>
              </div>
              <div className="niveletarsimit-table-right-row">
                <p>Bashkëpunimi joadekuat ndërmjet shkollës, familjes ose komunitetit.</p>
              </div>
              <div className="niveletarsimit-table-right-row">
                <p>Mësuesit përdorin qasje të specifikuara që janë kontekstualizuar.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12">      
        <NdaloReflekto 
          userId={userId}
          moduleId={moduleId}
          questionSlug={moduleClass}
          description="Bazuar ne përvojën tuaj dhe shkollën ku punoni ju, me cilin nga opsionet më lart identifikoheni ju si shkollë apo si mësimëdhënes(e). Shpjego në hapësirën më poshtë." 
          placeholder="Bazuar ne përvojën tuaj dhe shkollën ku punoni ju, me cilin nga opsionet më lart identifikoheni ju "
        />
      </div>
    </div>
  )
}

export default NiveletArsimit