import React, { useEffect, useState } from 'react'
import './PseArsimiGjitheperfshires.scss'
import { Link, useParams } from 'react-router-dom'

import NdaloReflekto from '../../../../../components/shared/NdaloReflekto/NdaloReflekto'
import { api } from '../../../../../api/axios';

function PseArsimiGjitheperfshires() {
  const [userId, setUserId] = useState(null);
  const {moduleId} = useParams();
  const {moduleClass} = useParams();

  async function fetchUserData(){
    try {
      const data = await api.get('user/myDetails');
      setUserId(data.data._id)
    } catch (error) {
      console.log('Error on fetching user data', error);
    }
  }
  
  useEffect(() => {
    fetchUserData();
  }, [])

  return (
    <div className="psearsimi-container container">
      <div className="col-lg-10 col-md-12 col-sm-12 list-container">
        <p className="explaining-text">Arsimi gjithëpërfshirës është një parim dhe një qasje që synon të sigurojë mundësi të barabarta për të gjithë nxënësit, duke respektuar diversitetin e tyre dhe duke përfshirë ata në procesin e mësimit. 
          Për të mbështetur këtë qasje, ekzistojnë disa konventa dhe dokumente ndërkombëtare që kanë rëndësi për arsimin gjithëpërfshirës. Disa prej tyre janë:
        </p>
        <div className="list">
          <div className="list-item">
            <div className="list-bullet"></div>
            <p className="list-item-text"><span className='list-item-text-span'>Konventa e OKB për të drejtat e fëmijës (1989)</span>  – çdo fëmijë ka të drejtë për arsim elementar pa pagesë, në frymën e mirëkuptimit, paqes, tolerancës, barazisë mes gjinive dhe miqësisë mes gjithë njerëzve.</p>
          </div>
          <div className="list-item">
            <div className="list-bullet"></div>
            <p className="list-item-text"><span className='list-item-text-span'>Deklarata e Salamankës (1994)</span>  – shkollat e rregullta me një orientim gjithëpërfshirës janë “mënyra më efektive për të luftuar qëndrimet diskriminuese, për të ndërtuar një shoqëri gjithëpërfshirëse dhe për arritjen e arsimimit për të gjithë”.</p>
          </div>
          <div className="list-item">
            <div className="list-bullet"></div>
            <p className="list-item-text"><span className='list-item-text-span'>Konventa e OKB për të drejtat e personave me aftësi të kufizuara (2008)</span> - obligimi bie mbi qeveritë që të sigurojnë një sistem arsimi plotësisht gjithëpërfshirës për të gjithë fëmijët.
            </p>
          </div>
          <div className="list-item">
            <div className="list-bullet"></div>
            <p className="list-item-text"><span className='list-item-text-span'>Qëllimet zhvillimore të qëndrueshme (SDG) 4</span>  – shteti duhet të sigurojë arsimim cilësor gjithëpërfshirës dhe të paanshëm/drejtë dhe të promovojë mundësi të të mësuarit gjatë tërë jetës për të gjithë.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 burimet-container">
        <p className='burimet-direct'>Dokumentet e lartëpërmendura mund t’i gjeni të plota tek <Link to={`/modules/${moduleId}/burimet`}>Burimet.</Link></p>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 barazia-image-container">
        <div className="barazia-image"></div>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12">
        <NdaloReflekto 
          userId={userId}
          moduleId={moduleId}
          questionSlug={moduleClass}
          description='Bazuar në përvojën tuaj si mësimdhënës(e), si e shpjegoni foton më lartë në kontekstin e shkollës / klasës?' 
          placeholder='Bazuar ne përvojën tuaj dhe shkollën ku punoni ju, me cilin nga opsionet më lart identifikoheni ju '/>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 science-studies-container">
        <p>Shumë studime shkencore të fushës, po ashtu argumentojnë përparësitë dhe përfitimet e arsimit gjithpërfshirës:</p>
        <ul>
          <li>Përmes arsimit gjithëpërfshirës, <span>të gjithë fëmijët përfitojnë</span> - Waldron, Cole, and Majd (2001).</li>
          <li><span>Fëmijët me aftësi të kufizuara mësojnë më mirë në klasa të rregullta</span> – Freeman and Alkin (2000).</li>
          <li>Arsimi gjithëpërfshirës <span>ofron mundësi për ndërveprim social.</span></li>
          <li>Arsimi gjithëpërfshirës <span>kontribuon në krijimin e një ”fryme të mirëkuptimit, paqes, tolerancës, barazisë mes gjinive, dhe miqësisë mes të gjithë njerëzve”</span> – Konventa për të drejtat e fëmijës, neni 29 (1989).</li>
        </ul>
      </div>
    </div>
  )
}

export default PseArsimiGjitheperfshires