import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import './ForgotPassword.scss';
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";
import { api } from '../../api/axios';
import { toast } from 'react-toastify';
function ForgotPassword() {
  
  const ForgotPasswordValidation = object().shape({
    email: string()
      .required("Ju lutem plotesoni kete te dhene")
      .email("Ju lutem vendosni email valide")
  });

  async function forgotPassword(values) {
    try {
      const data = await api.post(`/auth/reset`, {
        email: values.email
      });
      toast.success("Emaili u dergua me sukses", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error('Error:', error);
      toast.success("Emaili nuk u dergua. Provoni perseri.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 fluid-container forgotPassword-container">
      <div
        className="forgotPassword-form-container"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="title">Forgot Password</h3>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values) => {
            forgotPassword(values);
          }}
          validationSchema={ForgotPasswordValidation}
        >
          {({ errors }) => (
            <Form className="forgotPassword-form">
              <div>
                <div className="input-lg-container">
                  <div className="mail-img"></div>
                  <Field name="email" placeholder="Emaili" id="email" />
                  <ErrorMessage name="email" component="p" className="error-msg" />
                </div>
              </div>
              <button type="submit" className="btn-container btn-text">
                Forgot Password
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ForgotPassword;
