import React, { useEffect, useState } from 'react';
import { useDrag, DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import './VazhdimesiaFunksionimit.scss';
import { useParams } from 'react-router-dom';
import { api } from '../../../../../api/axios';
import { toast } from 'react-toastify';

const DraggableItem = ({ id, text }) => {
  const [, drag] = useDrag({
    type: 'ELEMENT',
    item: { id, text },
  });

  return (
    <div ref={drag} className="draggable-item">
      <p>{text}</p>
    </div>
  );
};
  
  const DroppableInput = ({ onDrop, id, value }) => {
    const [{ isOver }, drop] = useDrop({
      accept: 'ELEMENT',
      drop: (item) => onDrop(item.text, id),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });
  
    return (
      <div ref={drop} className={`droppable-input ${isOver ? 'is-over' : ''}`}>
          <input id={id} className='sm-input' value={value || ''} readOnly />
      </div>
    );
  };

function VazhdimesiaFunksionimit() {

  const { moduleId, moduleClass } = useParams();
  const [dragDropValues, setDragDropValues] = useState({});
  const [userId, setUserId] = useState();
  const [sendDragDropValuesBtn, setSendDragDropValuesBtn] = useState(true);

  const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
  const backend = isTouchDevice ? TouchBackend : HTML5Backend;

  const handleDrop = (text, inputId) => {
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
        inputElement.value = text;
        setDragDropValues(prev => ({ ...prev, [inputId]: text }));
    }
  };

  async function fetchUserData() {
    try {
        const data = await api.get(`user/myDetails`);
        setUserId(data.data._id); 
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }

  async function handleDragDropSubmittion() {
      const correctAnswers = {
          input1: "Faktori personal",
          input2: "Gjendja shëndetësore",
          input3: "Stuktura trupore",
          input4: "Funksioni trupor",
          input5: "Aktiviteti dhe pjesëmarrja",
          input6: "Faktori mjedisor"
      };

      const wrongInputs = Object.keys(correctAnswers).filter(inputId => {
          return dragDropValues[inputId] !== correctAnswers[inputId];
      }).map(inputId => inputId[inputId.length - 1]);

      if (wrongInputs.length > 0) {
          toast.error(`Gabim tek hapësira: ${wrongInputs.join(', ')}. Ju lutemi rregulloni përgjigjet tuaja.`, {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
          });
          return;
      }

      try {
        const labels = document.querySelectorAll('.checkbox-container label');
        const questionText = Array.from(labels).map(label => label.textContent);

        const answerOptions = Object.values(dragDropValues);

        const response = await api.post('miniquiz/submitMiniQuiz/multiple', {
            user: { _id: userId },
            moduleId: moduleId,
            questionSlug: moduleClass,
            quizType: 'DragDrop',
            questionText,
            answerOptions
        });
        
        toast.success("Përgjigjjet tuaja janë dërguar me sukses!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setSendDragDropValuesBtn(true);
      } catch (error) {
        console.error('Error on sending data', error);
          toast.error('Ndodhi një gabim gjatë dërgimit të përgjigjeve.', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        });
      }
  }

  async function fetchDragDrop(){
    if (!userId) {
      console.error('UserId is null or undefined');
      setSendDragDropValuesBtn(false);
      return;
    }
    try {
      const response = await api.get(`miniquiz/${userId}/${moduleId}/${moduleClass}/DragDrop`);
      const fetchedValues = response.data.miniQuiz[0]?.answerOptions;

      const newDragDropValues = {};
      fetchedValues.forEach((value, index) => {
          newDragDropValues[`input${index + 1}`] = value;
      });
      setDragDropValues(newDragDropValues);
      setSendDragDropValuesBtn(fetchedValues && fetchedValues.length > 0)
    } catch (error) {
      console.log('there was an error',error.message);
      setSendDragDropValuesBtn(false);
    }
  }

  async function handleDragDropUpdate() {
    try {
        const labels = document.querySelectorAll('.checkbox-container label');
        const questionText = Array.from(labels).map(label => label.textContent);

        const answerOptions = Object.values(dragDropValues);

        const response = await api.put(`miniquiz/${userId}/${moduleId}/${moduleClass}/multiple`, {
            user: { _id: userId },
            moduleId: moduleId,
            questionSlug: moduleClass,
            quizType: 'DragDrop',
            questionText,
            answerOptions
        });

        toast.success("Ndryshimet u ruajtën me sukses!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    } catch (error) {
        console.error('Error on updating data', error);
    }
  }


  useEffect(() => {
    fetchUserData();
  }, [])


  useEffect(() => {
    if(userId){
      fetchDragDrop();
    }
  }, [userId, moduleClass, moduleId])

  return (
     <div className="vazhdimesiafunksionit-container container">
        <div className="col-lg-10 col-md-12 col-sm-12 main-content-container">
            <div className="paragraph-container">
                <p>Të gjithë njerëzit mund të përjetojnë aftësi të kufizuar në masë më të madhe apo më të vogël në periudha të ndryshme të jetës.</p>
                <br />
                <p>KNF largohet nga qasja dikotomike (prania apo mungesa) e funksionimit dhe aftësisë së kufizuar, drejt qasjes që i vë ato në një vazhdimësi.</p>
            </div>
            <div className="img-container"></div>
            <div className="paragraph-container">
                <p>Tani pasi që kemi mësuar për modelin biopsikosocial më në hollësi, le të provojmë t’i identifikojmë komponentët e ndryshëm të tij nga tregimi i Gencit më poshtë.</p>
                <br />
                <p>
                  Genci është një djalë 7-të vjeçar i cili ka dëmtime në të parë (shikim). Tani mbaron klasën e parë në shkollën që gjendet afër shtëpisë së tij. Gjatë këtij viti shkollor, 
                  Genci ka arritur të përfshihet shumë mirë me grupin e nxënësve në aspektin social, por ka hasur vështirësi në përmbushjen e programit mësimor, përfshirë shkrimin, leximin, përllogaritjet matematikore, etj.</p>
                <br />
                <p>
                    Vështirësia e Gencit për të arritur rezultatet e parapara në lëndët mësimore vjen si rezultat i mungesës së mjeteve ndihmëse (materiale mësimore, tekste me shkronja të zmadhuara, etj.), 
                    mungesës së asistentit personal, përshtatjes së ambientit të klasës ku përfshihet ndriçimi i përshtatshëm, pozicioni i uljes, etj.</p>
                <br />
                <p>Genci ka dëmtim të pjesëshëm të kornesë së syrit dhe si rezultat i kësaj, ka cilësi të dobët të shikimit dhe dhembje koke.</p>
            </div>
        </div>
        <div className="col-lg-10 col-md-12 col-sm-12 table-container">
            <h3 className="table-title">Lidheni termet më poshtë me komponentët e duhur të modelit biopsikosocial.</h3>
            <DndProvider backend={backend}>
                <div className="table">
                    <div className="table-left">
                    <p>Vendosni fjalet e meposhtme ne hapesirat e duhura.</p>
                        <div className="elements">
                            <DraggableItem className="faktor-mjedisor" id="1" text="Faktori mjedisor" />
                            <DraggableItem id="2" text="Gjendja shëndetësore" />
                            <DraggableItem id="3" text="Funksioni trupor" />
                            <DraggableItem id="4" text="Faktori personal" />
                            <DraggableItem id="5" text="Stuktura trupore" />
                            <DraggableItem id="6" text="Aktiviteti dhe pjesëmarrja" />
                            {/* <DraggableItem id="7" text="Faktor mjedisor" />
                            <DraggableItem id="8" text="Funksioni trupor" />
                            <DraggableItem id="9" text="Faktor mjedisor" /> */}
    
                        </div>
                        <div className="checkbox-container">
                            <label htmlFor="vitet">57 vjeçar</label>
                            <DroppableInput onDrop={handleDrop} id="input1" value={dragDropValues.input1} />
                        </div>
                        <div className="checkbox-container">
                            <label htmlFor="klasa">Lëndimi i dorës</label>
                            <DroppableInput onDrop={handleDrop} id="input2" value={dragDropValues.input2} />
                        </div>
                        <div className="checkbox-container">
                            <label htmlFor="demtimi-syrit">Tendoni fleksor (kërci) dhe nervat</label>
                            <DroppableInput onDrop={handleDrop} id="input3" value={dragDropValues.input3} />
                        </div>
                        <div className="checkbox-container">
                            <label htmlFor="demtimi-kornese-syrit">Ndjesi si shpimi gjilpërash dhe mpirje</label>
                            <DroppableInput onDrop={handleDrop} id="input4" value={dragDropValues.input4} />
                        </div>
                        <div className="checkbox-container">
                            <label htmlFor="dhimbjakokes">Manipulimi dhe kapja e objekteve</label>
                            <DroppableInput onDrop={handleDrop} id="input5" value={dragDropValues.input5} />
                        </div>
                        <div className="checkbox-container">
                            <label htmlFor="veshtiresileximi">Allçia</label>
                            <DroppableInput onDrop={handleDrop} id="input6" value={dragDropValues.input6} />
                        </div>
                        {/* <div className="checkbox-container">
                            <label htmlFor="ndriçimi">Ndriçim i përshtatshëm në klasë</label>
                            <DroppableInput onDrop={handleDrop} id="input7" value={dragDropValues.input7} />
                        </div>
                        <div className="checkbox-container">
                            <label htmlFor="pozicioniuljes">Pozicioni i uljes</label>
                            <DroppableInput onDrop={handleDrop} id="input8" value={dragDropValues.input8} />
                        </div>
                        <div className="checkbox-container">
                            <label htmlFor="asistentipersonal">Asistenti personal</label>
                            <DroppableInput onDrop={handleDrop} id="input9" value={dragDropValues.input9} />
                        </div> */}
                    </div>
                </div>
            </DndProvider>
            <div className="table-buttons-container">
                <button disabled={sendDragDropValuesBtn} className={`table-button ${sendDragDropValuesBtn ? 'disabled' : ''}`} onClick={handleDragDropSubmittion}><p>Dërgo</p></button>
                {/* <div className="table-button" onClick={handleDragDropUpdate}><p>Ndrysho</p></div> */}
            </div>
        </div>
     </div>
  )
}

export default VazhdimesiaFunksionimit