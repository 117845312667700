import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Test.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { api } from "../../../../api/axios";
import { updateUserData } from "../../../../redux/app/auth/actions";
import { useDispatch } from "react-redux";
import Loader from "../../../shared/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Question from "../Question/Question";

function Test({ quizQuestions }) {
  const navigate = useNavigate();
  const { moduleId } = useParams();
  const [result, setResult] = useState("");
  const [percentage, setPercentage] = useState(null);
  const [color, setColor] = useState("");
  const [hasPassed, setHasPassed] = useState(null);
  const [fullName, setFullName] = useState("");
  const [hasCertification, setHasCertification] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [remainingTime, setRemainingTime] = useState(
    quizQuestions?.[0]?.duration * 60 * 1000
  );

  const initialValues = quizQuestions[0].questions.reduce((acc, question) => {
    acc[question._id] = question.questionText.endsWith(
      "(Më shumë se një përgjigje e saktë)"
    )
      ? []
      : "";
    return acc;
  }, {});

  async function fetchUserData(){
    try {
      const userData = await api.get('user/myDetails');
      setHasCertification(userData.data.gotCertificate);
      setFullName(userData.data.firstName + ' ' + userData.data.lastName)
    } catch (error) {
      console.log('There was an error', error);
    }
  }

  useEffect( () => {
    fetchUserData();
  } , [result]);

  const QuizValidation = Yup.object().shape(
    quizQuestions[0].questions.reduce((acc, question) => {
      acc[question._id] = question.questionText.endsWith(
        "(Më shumë se një përgjigje e saktë)"
      )
        ? Yup.array().min(1, "Select at least one option")
        : Yup.string().required("Option is required");
      return acc;
    }, {})
  );

  useEffect(() => {
    setRemainingTime(quizQuestions?.[0]?.duration * 60 * 1000);
  }, [quizQuestions]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevRemainingTime) => prevRemainingTime - 1000);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (remainingTime <= 0) {
      alert("Time has expired. Redirecting or closing the page...");
      navigate("/");
    }
  }, [remainingTime]);

  const handleDownload = async () => {
    try {
      const result = await api.post(
        `/quiz/generatePdf/${moduleId}`,
        {},
        { responseType: "blob" }
      );
      console.log("result", result);
      const file = new Blob([result.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);

      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);

      a.href = fileURL;
      a.download = `${fullName}-Certificate.pdf`;

      a.click();
      URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='test-container container'>
      {result.length === 0 ? (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              setLoading(true);
              const dataObject = {};
              for (const questionId in values) {
                if (!Array.isArray(values[questionId])) {
                  dataObject[questionId] = [values[questionId]];
                } else {
                  dataObject[questionId] = values[questionId];
                }
              }

              const submit = await api.post(`/quiz/submit/${moduleId}`, {
                answers: dataObject,
              });

              if (submit && submit.data) {
                if (submit.data.hasPassed) {
                  dispatch(updateUserData(submit.data.user));
                }

                setHasPassed(submit.data.hasPassed);

                if (submit.data.hasPassed) {
                  setResult(
                    `Keni kaluar testin me sukses, ${submit.data.score} nga ${submit.data.total} pyetje të sakta. `
                  );
                  setColor("#99BF5F");
                } else {
                  if (submit.data.score === 0) {
                    setResult(
                      `Nuk keni kaluar testin, ju nuk keni asnjë përgjigje të saktë.`
                    );
                  } else {
                    setResult(
                      `Nuk keni kaluar testin, vetëm ${submit.data.score} nga ${submit.data.total} pyetje të sakta.`
                    );
                  }
                  setColor("#E02216");
                }
                setPercentage(
                  parseFloat(
                    ((submit.data.score / submit.data.total) * 100).toFixed(1)
                  )
                );
              }
            } catch (error) {
              console.error("Error submitting test:", error);
            } finally {
              setLoading(false);
            }
          }}
          validationSchema={QuizValidation}
        >
          {({ errors, values, touched, handleSubmit }) => {
            return (
              <Form className='test-form'>
                {quizQuestions[0].questions.map((question, questionIndex) => (
                  <Question
                    key={question._id}
                    question={question}
                    values={values}
                    errors={errors}
                    touched={touched}
                    questionIndex={questionIndex}
                  />
                ))}
                <div className='buttons-container mt-5'>
                  <div
                    className='btn-container'
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    <div className='left arrow-image-container'></div>
                    <p className='btn-text'>Kthehu</p>
                  </div>
                  <button type='submit' className='btn-container finish-btn'>
                    <p className='btn-text'>Perfundo</p>
                    <div className='right arrow-image-container'></div>
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className='result-container'>
          <h1 className='percentage-info' style={{ color: color }}>
            {percentage}%
          </h1>
          <p>{result}</p>
          <div className='buttons-container '>
            {hasCertification &&
              <button
                className='btn-container finish-btn'
                onClick={handleDownload}
              >
                <p className='btn-text'>SHKARKO CERTIFIKATEN</p>
              </button>
            }
            {hasPassed ? (
              <>
                <button
                  className='btn-container finish-btn'
                  onClick={() => {
                    if (moduleId === "moduli-3") {
                      navigate("/feedback");
                      toast.success(
                        "Keni përfunduar me sukses trajnimin! Certifikatën do ta pranoni në email-in përkatës",
                        {
                          position: "bottom-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        }
                      );
                    } else {
                      navigate("/");
                      toast.success(
                        "Keni përfunduar me sukses modulin! Ju lutem vazhdoni tek moduli i radhës",
                        {
                          position: "bottom-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        }
                      );
                    }
                  }}
                >
                  <p className='btn-text'>VAZHDO</p>
                  <div className='right arrow-image-container'></div>
                </button>
              </>
            ) : (
              <div
                className='btn-container'
                onClick={() => {
                  navigate(`/modules/${moduleId}`);
                }}
              >
                <div className='left arrow-image-container'></div>
                <p className='btn-text'>KTHEHU</p>
              </div>
            )}
          </div>
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
}

export default Test;
