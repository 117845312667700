import React, { useEffect, useRef, useState } from "react";
import "./VerifyAccount.scss";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  setLoginModal,
  setLoginDispatcher,
  setRegisterModal,
} from "./../../redux/app/auth/actions";
import Loader from "../../components/shared/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../api/axios";

function VerifyAccount() {
  const dispatch = useDispatch();
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mountedVerify = useRef(false);
  const navigate = useNavigate();

  const userId = searchParams.get("userId");
  const token = searchParams.get("token");

  useEffect(() => {
    if (!mountedVerify.current) {
      mountedVerify.current = true;
      verifyAccountToken();
      // //Verify Link
      return;
    }
  }, []);

  const verifyAccountToken = async () => {
    await api
      .post(`auth/activate?token=${token}&userId=${userId}`)
      .then((response) => {
        setIsTokenValid(response.data.valid);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsTokenValid(false);
        setIsLoading(false);
      });
  };

  if (isLoading) return <Loader />;

  return (
    // <div className='home-container fluid-contianer'>

    <div className=" col-lg-12 col-md-12 col-sm-12 fluid-container login-container">
      <div
        className="login-form-container"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="title">
          {isTokenValid ? "Faleminderit" : "Linku nuk eshte valid!"}
        </h3>
        {isTokenValid && (
          <p className="description">
            Verifikimi i llogarise u krye me sukses!{" "}
          </p>
        )}
        {isTokenValid && (
          <button
            type="submit"
            className="btn-container"
            onClick={() => {
              dispatch(setLoginModal());
              navigate('/');
            }}
          >
            Kyqu
          </button>
        )}
      </div>
    </div>
    // </div>
  );
}

export default VerifyAccount;
