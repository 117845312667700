import React, { useEffect, useState } from 'react';
import { trainingPathCardData } from '../../components/screens/Home/TrainingPathCard/trainingPathCardData';
import './Home.scss';
import Loader from './../../components/shared/Loader/Loader';
import CardModule from '../../components/screens/Home/CardModule/CardModule';
import TrainingPathCard from '../../components/screens/Home/TrainingPathCard/TrainingPathCard';
import { api } from '../../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginModal, setRegisterModal } from '../../redux/app/auth/actions';

function Home() {
  const dispatch = useDispatch();
  const [module, setModule] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { loginModal, registerModal, authenticated, message } = useSelector(
    (state) => state?.user
  );

  //Get Modules
  useEffect(() => {
    getModule();
    setLoading(false);
  }, []);

  useEffect(() => {
    modulePassed();
  }, [authenticated]);

  const getModule = async () => {
    try {
      const module = await api.get('module');
      if (module && module.data) {
        setModule(module.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const modulePassed = async () => {
    if (localStorage.getItem('user')) {
      const data = await api.get('user/myDetails');
      if (data && data.data) {
        setData(data.data);
        // console.log(data.data);
      }
    }
  };

  const modulePassedResponse = {
    ...data,
    lastReadPage: data?.lastReadPage
      ? data?.lastReadPage.map((page) => ({
          ...page,
          hasPassed: page.quizResult?.passed || false,
        }))
      : [],
  };

  return (
    <>
      {!loading ? (
        <div className='home-container fluid-container'>
          <div
            className={
              false
                ? 'fluid-container banner-container active'
                : 'fluid-container banner-container'
            }
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 banner-subcontainer'>
                  <div className='banner-left col-lg-5 col-md-5 col-sm-10'>
                    <h1 className='banner-title'>
                      Trajnimi për KNF (Klasifikimi Ndërkombëtar i Funksionimit)
                    </h1>
                    <h2 className='banner-subtitle'>
                      Përdorimi i instrumenteve për vlerësimin pedagogjik të
                      nxënësve me nevoja të veçanta
                    </h2>
                    <p className='banner-description'>
                      Trajnimi i dixhitalizuar Klasifikimi Ndërkombëtar të
                      Funksionimit - Përdorimi i instrumenteve për vlerësimin
                      pedagogjik të nxënësve me nevoja të veçanta përfshirë
                      materialet ekzistuese të programit të trajnimit u krijua
                      me mbështetjen e projektit ‘’Fuqizimi i arsimit
                      gjithëpërfshirës dhe punësimit për fëmijët dhe të rinjtë
                      me aftësi të kufizuara‘’ të financuar nga Save the
                      Children Itali.
                    </p>
                    <p className='banner-description'>
                      Kursi është i hapur për të gjithë mësimdhënësit dhe është
                      falas!
                    </p>
                    {!authenticated && (
                      <div
                        className='banner-register-btn-container'
                        onClick={() => {
                          dispatch(setRegisterModal());
                        }}
                      >
                        <div className='banner-register-btn'>
                          <p>Regjistrohu</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='banner-right col-lg-6 col-md-7 col-sm-10'>
                    <div className='banner-image-container'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container mt-5'>
            <div className='row mb-4'>
              <div className='col-lg-12 col-md-12 col-sm-10 training-menu-container'>
                <div className='title-container'>
                  <h4 className='title'>Menyja e trajnimit</h4>
                </div>
                <div className='description-container'>
                  <p className='description'>
                    Programi i trajnimit Klasifikimi Ndërkombëtar të
                    Funksionimit dhe përdorimi i instrumenteve për vlerësimin
                    pedagogjik të nxënësve me nevoja të veçanta është i ndarë në
                    tre module. Pas përfundimit të tre moduleve, shfaqet një
                    test në formë të kuizit. Ju duhet t’i përgjigjeni saktë së
                    paku 80% të pyetjeve për të kaluar në modulin pasues dhe për
                    të përfunduar trajnimin.
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div className='row cards-container'>
                  {module &&
                    module?.length > 0 &&
                    module?.map((card, index) => {
                      const userPage = modulePassedResponse.lastReadPage.find(
                        (page) => page.slug === card.slug
                      );
                      const hasPassed = userPage ? userPage.hasPassed : false;

                      return (
                        <div
                          className={`col-lg-4 col-md-6 col-sm-10 card-cont`}
                          key={index}
                        >
                          <CardModule
                            cardId={index + 1}
                            title={card.title}
                            description={card.description}
                            buttonText={hasPassed ? 'Përfunduar' : 'Vazhdo'}
                            path={card.slug}
                            hasPassed={hasPassed}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row training-path-container'>
              <div className='col-lg-12 col-md-12 col-sm-12 training-path-subcontainer'>
                <div className='col-lg-6 col-md-5 col-sm-10 path-image-container'>
                  <div className='path-image'></div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-10 training-path'>
                  <h1 className='title'>
                    Si të përfundoni me sukses trajnimin:
                  </h1>
                  {trainingPathCardData?.map((card) => {
                    return (
                      <div key={card.id}>
                        <TrainingPathCard
                          title={card.title}
                          path={card.path}
                          description={card.description}
                          img={card.img}
                          alt={card.alt}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Home;
