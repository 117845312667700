import React, { useEffect, useState } from 'react'
import './PerfitimetNgaArsimi.scss'

import NdaloReflekto from '../../../../shared/NdaloReflekto/NdaloReflekto'
import { useParams } from 'react-router-dom';
import { api } from '../../../../../api/axios';
import DropDownContainer from '../../../../shared/ModuleClass/Module1/DropDownContainer/DropDownContainer';

function PerfitimetNgaArsimi() {

  const [userId, setUserId] = useState(null);
  const {moduleId} = useParams();
  const {moduleClass} = useParams();

  async function fetchUserData(){
    try {
      const data = await api.get('user/myDetails');
      setUserId(data.data._id)
    } catch (error) {
      console.log('Error on fetching user data', error);
    }
  }
  
  useEffect(() => {
    fetchUserData();
  }, [])

  
  return (
    <div className='container perfitimetarsimit-container'>
      <div className="col-lg-10 col-md-12 col-sm-12 descriptions-container">
        <p>Arsimi gjithëpërfshirës përfiton shoqërinë dhe individët në shumë aspekte. Këtu janë disa prej përfitimeve kryesore të arsimit gjithëpërfshirës:
        </p>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 perfitimetarsimit-dropdowns-container">
        <DropDownContainer 
          title="Barazi në mundësi:"
          content="Arsimi gjithëpërfshirës i jep të drejtën të gjithë nxënësve, pavarësisht prej prejardhjes, orientimit seksual, statusit socio-ekonomik, nevojave te veçanta, ose ndonjë sëmundjeje të caktuar, të marrin një arsim cilësor. Kjo ndihmon në zvogëlimin e përçarjeve sociale dhe në ndërtimin e një shoqërie më të drejtë dhe më të barabartë."
        />
        <DropDownContainer 
          title="Gjithëpërfshirje sociale:"
          content="Arsimi gjithëpërfshirës i jep çdo individit mundësinë të arsimohet në një mjedis të përbashkët me të gjithë të tjerët. Kjo ndihmon në krijimin e një kulture gjithëpërfshirëse duke i mirëpritur, duke promovuar tolerancën dhe respektin ndaj ndryshimeve të tjera dhe duke mos shpërndarë stereotipe negative."
        />
        <DropDownContainer 
          title="Zhvillim personal:"
          content="Arsimi gjithëpërfshirës i jep çdo individit mundësinë të zhvillojë plotësisht potencialin e tij. Nxënësit kanë qasje në resurset dhe ndihmën e nevojshme për të zhvilluar aftësitë e tyre kognitive, emocionale, sociale dhe fizike. Kjo i ndihmon ata të rriten si persona të sigurtë dhe të pavarur, të aftë për të kontribuar në shoqëri."
        />
        <DropDownContainer 
          title="Krijimi i një fuqie punëtore të kualifikuar:"
          content="Arsimi gjithëpërfshirës ndihmon në krijimin e një fuqie punëtore të kualifikuar dhe të aftë për t'iu përgjigjur sfidave të tregut të punës. Nxënësit kanë mundësinë të zhvillojnë aftësitë e tyre profesionale dhe të marrin njohuri të nevojshme për të qenë konkurrues në një mjedis global."
        />
        <DropDownContainer 
          title="Ndikim pozitiv në zhvillimin ekonomik:"
          content="Arsimi gjithëpërfshirës ka ndikim pozitiv në zhvillimin ekonomik të një vendi. Një forcë pune e arsimuar dhe e aftë krijon mundësinë për inovacion, prodhimtari të lartë dhe rritje ekonomike të qëndrueshme."
        />
        <DropDownContainer 
          title="Ruajtja e paqes dhe stabilitetit shoqëror:"
          content="Arsimi gjithëpërfshirës i jep të drejtën të gjithë individëve të mësojnë rreth vlerave dhe të drejtave themelore njerëzore. Kjo ndihmon në promovimin e paqes, tolerancës dhe dialogut në shoqëri, duke reduktuar konfliktet dhe tensionet sociale."
        />
        <DropDownContainer 
          title="Përparimi i shoqërisë:"
          content="Arsimi gjithëpërfshirës i jep shoqërisë mundësinë të përparojë dhe të zhvillohet në të gjitha fushat e jetës, duke kultivuar dije, ide dhe inovacione të reja. Një shoqëri me një bazë arsimore të fortë është më e aftë për të përballuar sfidat dhe për të krijuar një të ardhme më të mirë për të gjithë anëtarët e saj."
        />
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12">
        <p>
          Këto janë vetëm disa prej përfitimeve të arsimit gjithëpërfshirës. Arsimi gjithëpërfshirës ka një ndikim të thellë dhe të gjerë në shoqëri, duke krijuar një bazë të fortë për zhvillim individual dhe kolektiv.
        </p>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 ndaloreflekto-container">
        <NdaloReflekto 
          userId={userId}
          moduleId={moduleId}
          questionSlug={moduleClass}
          description="Bazuar në përvojën tuaj si mësimëdhënës(e) shto përfitimet tjera që ka arsimi gjithëpërfshirës për fëmijët/nxënësit?" 
          placeholder="Bazuar ne përvojën tuaj dhe shkollën ku punoni ju, me cilin nga opsionet më lart identifikoheni ju"
        />
      </div>
  </div>

  )
}

export default PerfitimetNgaArsimi