import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, useNavigate, useRevalidator } from "react-router-dom";
import { moduleClassContentData } from "./moduleData";
import { useSelector, useDispatch } from "react-redux";
import "./Module.scss";

import NdaloReflekto from "./../../components/shared/NdaloReflekto/NdaloReflekto";
import { ToastContainer, toast } from "react-toastify";
import moduleImage1 from "./../../assets/screens/module-assets/module1-banner.svg";
import moduleImage2 from "./../../assets/screens/module-assets/module2-banner.svg";
import moduleImage3 from "./../../assets/screens/module-assets/module3-banner.svg";
import clockImg1 from "./../../assets/screens/module-assets/clock1.svg";
import clockImg2 from "./../../assets/screens/module-assets/clock2.svg";
import clockImg3 from "./../../assets/screens/module-assets/clock3.svg";
import { api } from "../../api/axios";
import Loader from "../../components/shared/Loader/Loader";

function Module(props) {
  const { user } = useSelector((state) => state?.user);

  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const { moduleId, moduleClass } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [backgroundBannerColor, setBackgroundBannerColor] = useState("");
  const [clockImg, setClockImg] = useState("");
  const [loading, setLoading] = useState(true);
  const mountedSlug = useRef(false);
  const [activeContentId, setActiveContentId] = useState(null);

  async function fetchUserData(){
    try {
      const data = await api.get('user/myDetails');
      setUserId(data.data._id)
    } catch (error) {
      console.log('Error on fetching user data', error);
    }
  }
  
  useEffect(() => {
    fetchUserData();
  }, [])

  function checkModuleId(id) {
    switch (id) {
      case "moduli-1":
        setTitle(`MODULI 1`);
        setDescription(
          `Përkufizimi, qëllimi i trajnimit, dhe përfitimet. Parimet e arsimit gjithëpërfshirës, nivelet e gjithëpërfshirjes, dhe korniza ligjore në Kosovë`
        );
        setBackgroundImage(moduleImage1);
        setBackgroundBannerColor("#FFF0E8");
        setClockImg(clockImg1);
        break;
      case "moduli-2":
        setTitle(`MODULI 2`);
        setDescription(
          `Modeli biopsikosocial i Klasifikimit Ndërkombëtar të Funksionimit, aftësisë së kufizuar dhe shëndetit (KNF)`
        );
        setBackgroundImage(moduleImage2);
        setBackgroundBannerColor("#E8F3FF");
        setClockImg(clockImg2);
        break;
      case "moduli-3":
        setTitle(`MODULI 3`);
        setDescription(
          `Formulari i raportimit nga shkolla dhe raporti i monitorimit`
        );
        setBackgroundImage(moduleImage3);
        setBackgroundBannerColor("#FFE8F0");
        setClockImg(clockImg3);
        break;
      default:
        console.log("?");
    }
  }

  const matchedModule = moduleClassContentData.find(
    (moduleContent) => moduleContent.id.toString() === moduleId
  );

  const contentToDisplay = matchedModule ? matchedModule.contentData : [];

  useEffect(() => {
    checkModuleId(moduleId);

    if (contentToDisplay.length > 0) {
      setActiveContentId(contentToDisplay[0].id);
    }
  }, [moduleId, contentToDisplay]);

  //Check access

  useEffect(() => {
    if (!mountedSlug.current) {
      mountedSlug.current = true;
      valideAccess();
      return;
    }
  }, []);

  const valideAccess = async () => {
    let check = await api.get(`user/access/${moduleId}`);
    if (check.data.canAccess) {
      setLoading(false);
      //Push to active page if exists
    } else {
      mountedSlug.current = true;
      toast.error("Ju duhet te mbaroni trajnimet paraprake!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate(`/`);
      return false;
    }
  };

  
  const lastModule =
  user ? matchedModule &&
    matchedModule.ids &&
    matchedModule.ids[
      user.lastReadPage.find((item) => item.slug === moduleId)?.pageName
    ] || 1 : 1;

  return (
    <>
      {!loading ? (
        <div className="module-container fluid-container">
          <div
            className="module-banner-container fluid-container"
            style={{ backgroundColor: backgroundBannerColor }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 module-banner-subcontainer">
                  <div className="module-banner-left col-lg-5 col-md-5 col-sm-10">
                    <h1 className="module-banner-title">{title}</h1>
                    <p className="module-banner-description">{description}</p>
                    <div className="module-banner-time">
                      <div
                        className="clock-img"
                        style={{ backgroundImage: `url(${clockImg})` }}
                      ></div>
                      <p>Rreth 50 minuta</p>
                    </div>
                  </div>
                  <div className="module-banner-right col-lg-6 col-md-7 col-sm-10">
                    <div
                      className="module-banner-image-container"
                      style={{ backgroundImage: `url(${backgroundImage})` }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="row module-content-container">
              <div className="col-lg-8 col-md-10 col-sm-10 module-content-subcontainer">
                <h1 className="module-content-title">Përmbajtja e modulit:</h1>
                {contentToDisplay.map((data, index) => {
                  if (data.title !== "Burimet") {
                    return (
                      <div
                        className={`content-container ${
                          lastModule >= index + 1 ? "active" : ""
                        }`}
                        key={data.id}
                        onClick={() => {
                          setActiveContentId(data.id);
                        }}
                      >
                        <p className="content-title">{data.title}</p>
                        {lastModule >= index + 1 &&
                          contentToDisplay.length > 0 && ( // Conditionally render the first button
                            <div
                              className="content-btn-container"
                              onClick={() => {
                                navigate(`/modules/${moduleId}/${data.path}`);
                              }}
                            >
                              <h5 className="content-btn-text">Vazhdo</h5>
                              <div className="content-btn-arrow-icon"></div>
                            </div>
                          )}
                      </div>
                    );
                  } else {
                    return (
                      <div className="content-container">
                        <Link
                          to={`/modules/${moduleId}/burimet`}
                          className="content-title burimet-title"
                        >
                          Burimet
                        </Link>
                      </div>
                    );
                  }
                })}

                {moduleId === "moduli-1" && (
                  <NdaloReflekto
                    userId={userId}
                    moduleId = {moduleId}
                    questionSlug="module1Slug"
                    description="Sipas jush, cilat janë përfitimet nga arsimi gjithëpërfshirës? Listoni të paktën tre ose katër përfitime."
                    placeholder="Bazuar ne përvojën tuaj dhe shkollën ku punoni ju, me cilin nga opsionet më lart identifikoheni ju "
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Module;
