import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./ModuleTest.scss";
import TestExplain from "../../components/screens/ModuleTest/TestExplain/TestExplain";
import HyrjeTest from "../../components/screens/ModuleTest/HyrjeTest/HyrjeTest";
import clockImg1 from "./../../assets/screens/module-assets/clock1.svg";
import clockImg2 from "./../../assets/screens/module-assets/clock2.svg";
import clockImg3 from "./../../assets/screens/module-assets/clock3.svg";
import Test from "../../components/screens/ModuleTest/Test/Test";
import { api } from "../../api/axios";
import { moduleClassContentData } from "../Module/moduleData";
import Loader from "../../components/shared/Loader/Loader";

function ModuleTest(props) {
  const { moduleId } = useParams();
  const { moduleClass } = useParams();
  const [loading, setLoading] = useState(true);
  const [quizStep, setQuizStep] = useState(0);

  const [data, setData] = useState([]);

  const [backgroundBannerColor, setBackgroundBannerColor] = useState("");
  const [clockImg, setClockImg] = useState("");

  async function fetchData() {
    const data = await api.get(`quiz/${moduleId}`);
    setData(data.data);
    setLoading(false);
  }

  function checkModuleId(id) {
    switch (id) {
      case "moduli-1":
        setBackgroundBannerColor("#FFF0E8");
        setClockImg(clockImg1);
        break;
      case "moduli-2":
        setBackgroundBannerColor("#E8F3FF");
        setClockImg(clockImg2);
        break;
      case "moduli-3":
        setBackgroundBannerColor("#FFE8F0");
        setClockImg(clockImg3);
        break;
      default:
        setBackgroundBannerColor("#FFF0E8");
    }
  }

  useEffect(() => {
    checkModuleId(moduleId);
  }, [moduleId]);

  const updateQuizStep = () => {
    if (quizStep < quizComponents.length) {
      setQuizStep(quizStep + 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const matchedModuleClass = moduleClassContentData.find((moduleContent) =>
    moduleContent.contentData.some((data) => data.path === moduleClass)
  );

  const titleToDisplay = matchedModuleClass
    ? matchedModuleClass.contentData.find((data) => data.path === moduleClass)
        .title
    : "";

  const matchedModule = moduleClassContentData.find(
    (moduleContent) => moduleContent.id.toString() === moduleId
  );

  const contentToDisplay = matchedModule ? matchedModule.contentData : [];

  const quizComponents = [
    <TestExplain updateQuizStep={updateQuizStep} />,
    <HyrjeTest updateQuizStep={updateQuizStep} />,
    <Test quizQuestions={data.quiz} />,
  ];

  return (
    <>
    {!loading ? (
          <div className="test-class-container container-fluid">
          <div
            className="container-fluid test-class-banner-container"
            style={{ backgroundColor: backgroundBannerColor }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 test-class-banner-subcontainer">
                  <div className="test-class-banner col-lg-7 col-md-7 col-sm-9">
                    <h1 className="test-class-banner-title">
                      Testi - {data?.module?.title}
                    </h1>
                    <div className="test-class-banner-time">
                      <div
                        className="clock-img"
                        style={{ backgroundImage: `url(${clockImg})` }}
                      ></div>
                      <p>Rreth 10 minuta</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row test-class-content-container">
              <div className="col-lg-8 col-md-12 test-class-component-container">
                {quizComponents[quizStep]}
              </div>
              <div className="col-lg-4 col-md-12 test-class-content-side-container">
                <h1 className="test-class-content-title">Përmbajtja e modulit:</h1>
                {contentToDisplay.map((data, index) => {
                  const isActive = data.path === moduleClass;
                  const isLastItem = index === contentToDisplay.length - 1;
                  return (
                    <div
                      className={`class-content-container ${
                        isActive ? "active" : ""
                      }`}
                      key={data.id}
                    >
                      <Link
                        to={`/modules/${moduleId}/${data.path}`}
                        className={`class-content-link ${
                          isLastItem ? "last-item" : ""
                        }`}
                      >
                        {data.title}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )
    }

    </>
  );
}

export default ModuleTest;
