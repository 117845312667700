import React, { useEffect, useState } from 'react';
import './MemoryGame.scss';
import { questionData } from './questionData';
import MemoryGameQuestion from './MemoryGameQuestion';
import { api } from '../../../../../../api/axios';
import { toast } from 'react-toastify';

function MemoryGame({ moduleId, moduleClass, userId }) {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [originalAnswer, setOriginalAnswer] = useState([]);
  const [memorygameAnswer, setMemorygameAnswer] = useState([]);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)

  const updateSelectedOption = (imageSrc, selectedOptionText) => {
    setSelectedOptions({
      ...selectedOptions,
      [imageSrc]: selectedOptionText,
    });
  };

  async function updateMemoryGame() {
    try {
      const updatedMemorygameAnswer = memorygameAnswer.map((option, index) => {
        const selectedOption = selectedOptions[questionData[index].imageSrc];
        if (selectedOption !== undefined) {
          return selectedOption;
        } else {
          return option;
        }
      });

      const putNewMemoryGameValues = await api.put(
        `miniquiz/${userId}/${moduleId}/${moduleClass}/multiple`,
        {
          answerOptions: updatedMemorygameAnswer,
          quizType: 'MemoryGame',
        }
      );
      toast.success('Pergjigja juaj eshte perditesuar me sukses!', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      console.log(selectedOptions);
    } catch (error) {
      console.log('There is an error', error.message);
    }
  }

  async function handleMemoryGameSubmittion() {
    try {
      // Check wrong answers
      const wrongAnswers = questionData.filter(question => {
        const selectedAnswer = selectedOptions[question.imageSrc];
        return selectedAnswer !== question.correctAnswer;
      }).map(question => `Pyetja ${question.id}`);
  
      if (wrongAnswers.length > 0) {
        // Display a toast with the wrong answers
        toast.error(`Gabim tek: ${wrongAnswers.join(', ')}`, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        // Process the successful submission if all answers are correct
        const answerOptions = Object.values(selectedOptions);
        const questionText = Object.keys(selectedOptions).map((imageSrc, index) => {
          const question = questionData.find(q => q.imageSrc === imageSrc);
          return question ? `Pyetja ${question.id}` : `Pyetja ${index + 1}`;
        });
        const response = await api.post('miniquiz/submitMiniQuiz/multiple', {
          user: { _id: userId },
          moduleId: moduleId,
          questionSlug: moduleClass,
          quizType: 'MemoryGame',
          questionText,
          answerOptions,
        });
        toast.success('Pergjigja juaj eshte ruajtur me sukses!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        setNextButtonDisabled(true);
      }
    } catch (error) {
      console.log('There was an error', error);
      toast.error('Ndodhi një gabim gjatë dërgimit të përgjigjeve.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }
  
  async function fetchMemoryGameAnswersValue() {
    if (!userId) {
      console.error('UserId is null or undefined');
      setNextButtonDisabled(false); 
      return;
    }
    try {
      let fetchAnswer = await api.get(
        `miniquiz/${userId}/${moduleId}/${moduleClass}/MemoryGame`
      );
      const answerOptions = fetchAnswer?.data?.miniQuiz[0]?.answerOptions;
      setOriginalAnswer(answerOptions);
      setMemorygameAnswer(answerOptions);
      setNextButtonDisabled(answerOptions && answerOptions.length > 0); 
    } catch (error) {
      console.error('Error:', error);
      setNextButtonDisabled(false); 
    }
  }
  

  useEffect(() => {
    if (userId) {
      fetchMemoryGameAnswersValue();
    }
  }, [userId, moduleId, moduleClass]);

  return (
    <div className='col-lg-10 col-md-12 col-sm-12 memory-game-container'>
      <h3>Loja e Kujtesës:</h3>
      <div className='memory-game-subcontainer'>
        <h3>Nivelet e gjithëpërfshirjes</h3>
        <div className='memory-game'>
          {questionData.map((question) => {
            return (
              <MemoryGameQuestion
                key={question.id}
                imageSrc={question.imageSrc}
                questionOptions={question.questionOptions}
                updateSelectedOption={updateSelectedOption}
                originalAnswer={originalAnswer[question.id - 1]} // Pass the correct originalAnswer for each question
              />
            );
          })}
        </div>
        <div className='buttons-container'>
          <button 
            disabled={nextButtonDisabled}
            className={`btn-container ${nextButtonDisabled ? 'disabled' : ''}`} onClick={handleMemoryGameSubmittion}>
            <h5 className='btn-text'>Dergo</h5>
          </button>
          {/* <div className='btn-container' onClick={updateMemoryGame}>
            <h5 className='btn-text'>Ndrysho</h5>
          </div> */}
        </div>
      </div>
      <p className='copyright'>©UNIA.be</p>
    </div>
  );
}

export default MemoryGame;
