export const setToken = (user, token, exp) => {
  var myDate = new Date(exp);
  const tokenExpire = myDate.getTime();

  localStorage.setItem("token", token);
  localStorage.setItem("user", user);
  localStorage.setItem("exp", tokenExpire);
};

export const removeToken = () => {
  if (localStorage.getItem("token")) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }

  if (localStorage.getItem("exp")) localStorage.removeItem("exp");
};

export const isTokenValid = () => {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const expTime = localStorage.getItem("exp");

  if (!token || !expTime) return { isValide: false };

  try {
    const currentTime = Date.now() / 1000;

    if (expTime < currentTime) {
      removeToken();
      return { isValide: false };
    }
    return { isValid: true, user: user, token: token, expTime: expTime };
  } catch (err) {
    removeToken();
    return { isValide: false };
  }
};
