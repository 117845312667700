import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Navbar from "./components/shared/Navbar/Navbar";
import { isTokenValid } from "./api/utils";
import { setLoginModal, validateToken } from "./redux/app/auth/actions";
import AllRoutes from "./routes";
import Footer from "./components/shared/Footer/Footer";
import Login from "./components/shared/auth/Login/Login";
import Signup from "./components/shared/auth/Signup/Signup";
import Modal from "./components/shared/Modal/Modal";
import Loader from "./components/shared/Loader/Loader";

function App() {
  const dispatch = useDispatch();
  const { loginModal, registerModal, authenticated, message } = useSelector(
    (state) => state?.user
  );

  useEffect(() => {
    const isAuth = isTokenValid();
    if (isAuth.isValid) {
      dispatch(validateToken(isAuth.token, isAuth.user, isAuth.expTime));
    }
  }, []);

  useEffect(() => {
    if (message?.type && message?.type == "success") {
      toast.success(message.msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (message?.type == "error") {
      toast.error(message.msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [message]);

  return (
    <>
      {loginModal && !authenticated && (
        <Modal
          closeModal={() => {
            dispatch(setLoginModal());
          }}
        >
          <Login />
        </Modal>
      )}
      {registerModal && !authenticated && <Signup />}
      <ToastContainer />
      <Navbar />
      <AllRoutes />
      <Footer />
    </>
  );
}

export default App;
