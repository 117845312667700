import React, { useState, useEffect, useMemo } from 'react';
import './MyProfile.scss';
import edit from './../../assets/screens/myprofile-assets/edit.svg';
import award from './../../assets/screens/myprofile-assets/award.svg';
import { api } from '../../api/axios';
import Loader from '../../components/shared/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import PDFViewer from './PDFViewer';

function MyProfile() {
  const [data, setData] = useState([]);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [editName, setEditName] = useState(false);
  const [editLastname, setEditLastname] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [originalName, setOriginalName] = useState('');
  const [originalLastName, setOriginalLastName] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfSlug, setPdfSlug] = useState('');

  const navigate = useNavigate();

  function getModuleNumber(slug) {
    const matches = slug.match(/\d+$/);
    return matches ? matches[0] : 'N/A'; // If a number is found, return it; otherwise, return 'N/A'
  }

  function getModuleDescription(slug) {
    switch (slug) {
      case 'moduli-1':
        return `9`;
      case 'moduli-2':
        return `7`;
      case 'moduli-3':
        return `8`;
      default:
        return ``;
    }
  }

  async function fetchData() {
    try {
      const data = await api.get(`user/myDetails`);
      setData(data.data);
      console.log(data.data);
      setLoading(false);
      setName(data.data.firstName);
      setLastName(data.data.lastName);
      setPdfSlug(data.data.certificate);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }

  const getUserCertificate = useMemo(() => {
    async function getCertificate(pdfSlug) {
      try {
        const response = await api.get(`quiz/pdf/${pdfSlug}`, {
          responseType: 'blob',
        });
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
      } catch (error) {
        console.error('Error fetching the certificate:', error);
      }
    }
    return getCertificate;
  }, [pdfSlug]);

  useEffect(() => {
    if (pdfSlug) {
      getUserCertificate(pdfSlug);
    }
  }, [pdfSlug]);

  async function updateData() {
    try {
      const updatedData = {
        firstName: name,
        lastName: lastName,
      };

      await api.put('user', updatedData);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating data:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.firstName) {
      setOriginalName(data.firstName);
    }
    if (data.lastName) {
      setOriginalLastName(data.lastName);
    }
  }, [data]);

  useEffect(() => {
    if (editName || editLastname) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [editName, editLastname]);

  return (
    <>
      {!loading ? (
        <div className='myprofile-container fluid-container'>
          <div className='container-fluid myprofile-banner-container'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 myprofile-banner-subcontainer'>
                  <h1 className='myprofile-banner-title'>Profili im</h1>
                </div>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row myprofile-content-container'>
              <div className='col-lg-4 col-md-6 col-sm-12 myprofile-info-container'>
                <div className='image-info-container'>
                  <div className='image-container'>
                    <p>
                      {name &&
                        lastName &&
                        `${name.charAt(0).toUpperCase()}${lastName
                          .charAt(0)
                          .toUpperCase()}`}
                    </p>
                  </div>
                </div>
                <div className='infot-container'>
                  <div className='emri-container'>
                    <div className='emri-subcontainer'>
                      <p className='emri-top'>Emri</p>
                      {editName ? (
                        <div className='input-lg-container'>
                          <input
                            type='text'
                            placeholder='Emri'
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </div>
                      ) : (
                        <p className='emri-bottom'>{name}</p>
                      )}
                    </div>
                    <div
                      className='edit-container'
                      onClick={() => {
                        setEditName(!editName);
                      }}
                    >
                      <img src={edit} alt='' />
                    </div>
                  </div>
                  <div className='emri-container'>
                    <div className='emri-subcontainer'>
                      <p className='emri-top'>Mbiemri</p>
                      {editLastname ? (
                        <div className='input-lg-container'>
                          <input
                            type='text'
                            placeholder='Mbiemri'
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                        </div>
                      ) : (
                        <p className='emri-bottom'>{lastName}</p>
                      )}
                    </div>
                    <div
                      className='edit-container'
                      onClick={() => {
                        setEditLastname(!editLastname);
                      }}
                    >
                      <img src={edit} alt='' />
                    </div>
                  </div>
                  <div className='emri-container'>
                    <div className='emri-subcontainer'>
                      <p className='emri-top'>Email</p>
                      <p className='emri-bottom'>{data.email}</p>
                    </div>
                  </div>
                  {isEditing && (
                    <button
                      className={`btn-container`}
                      onClick={() => {
                        updateData();
                        setEditLastname(false);
                        setEditName(false);
                      }}
                      disabled={
                        name === originalName && lastName === originalLastName
                      }
                    >
                      <p className='btn-text'>Ndrysho</p>
                    </button>
                  )}
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12 courses-container'>
                <h2>Kurset</h2>
                <div className='course-container'>
                  <div className='course-name-container'>
                    <h1>Emri Kursit</h1>
                    {data.gotCertificate ? (
                      <img src={award} alt='award-image' />
                    ) : (
                      ''
                    )}
                  </div>
                  {data.lastReadPage.map((moduleData) => {
                    const moduleNumber = getModuleNumber(moduleData.slug);
                    const moduleDescription = getModuleDescription(
                      moduleData.slug
                    );

                    // Check if moduleData.quizResult is defined before accessing its properties
                    const quizResultScore = moduleData.quizResult
                      ? moduleData.quizResult.score
                      : 0;
                    const resultInPercentage = parseFloat(
                      ((quizResultScore * 100) / moduleDescription).toFixed(1)
                    );
                    return (
                      <div key={moduleData._id} className='module-container'>
                        <h1 className='module-title'>Moduli {moduleNumber}</h1>
                        {resultInPercentage > 80 ? (
                          <>
                            <p className='module-result'>
                              {resultInPercentage}%
                            </p>
                            <p className='module-description'>
                              Keni kaluar testin me sukses, {quizResultScore}{' '}
                              nga {moduleDescription} pyetje të sakta.
                            </p>
                          </>
                        ) : (
                          <>
                            {''}
                            <p className='module-description'>
                              Ju nuk keni kaluar ende testin e modulit{' '}
                              {moduleNumber}. Provoni perseri{' '}
                              <span
                                onClick={() => {
                                  navigate(`/quiz/moduli-${moduleNumber}`);
                                }}
                              >
                                ketu
                              </span>
                            </p>
                          </>
                        )}
                      </div>
                    );
                  })}
                    <div className="module-container">
                      <h1 className='module-title'>Certifikata juaj:</h1>
                      {pdfUrl && <PDFViewer pdfUrl={pdfUrl} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default MyProfile;