import React, { useEffect, useState } from 'react'
import './PershkrimiFunksionimit.scss'
import NdaloReflekto from '../../../../shared/NdaloReflekto/NdaloReflekto'
import { useParams } from 'react-router-dom';
import { api } from '../../../../../api/axios';

function PershkrimiFunksionimit() {
    
    const {moduleId} = useParams();
    const {moduleClass} = useParams();
    const [userId, setUserId] = useState(null);

    async function fetchUserData(){
        try {
          const data = await api.get('user/myDetails');
          setUserId(data.data._id)
        } catch (error) {
          console.log('Error on fetching user data', error);
        }
      }
      
      useEffect(() => {
        fetchUserData();
      }, [])
    
  return (
    <div className="pershkrimifunksionimit-container container">
        <div className="col-lg-10 col-md-12 col-sm-12 stop-reflect-container">
            <NdaloReflekto
                userId={userId}
                moduleId={moduleId}
                questionSlug={moduleClass}
                description='Sipas jush, cili është dallimi mes aftësies dhe funksionimit?'
                placeholder='Bazuar ne përvojën tuaj dhe shkollën ku punoni ju, me cilin nga opsionet më lart identifikoheni ju ' 
            />
        </div>
        <div className="col-lg-10 col-md-12 col-sm-12 content-container">
            <h3>Funksionimi dhe aftësia e kufizuar</h3>
            <div className="content-subcontainer">
                <div className="content-paragraph-container">
                    <p>KNF, e cila është shkurtesë për "Klasifikimi ndërkombëtar i aftësisë së kufizuar, funksionimit dhe shëndetit", ndan shëndetin në dy aspekte kryesore: <span>aftësinë dhe funksionimin.</span></p>
                    <p><span>Aftësia</span> është kapaciteti i një individi për të kryer një veprim ose detyrë të caktuar. Ajo përfshin aftësitë fizike, kognitive, sociale dhe emocionale të një personi.</p>
                </div>
                <div className="content-list-container">
                    <p>Aftësitë janë të ndara në katër kategori bazë:</p>
                    <ul>
                        <li><span>Aftësitë kognitive:</span> Përfshijnë aftësitë të tilla si kujtesa, vëmendja, mendimi, perceptimi dhe aftësitë gjuhësore.</li>
                        <li><span>Aftësitë fizike:</span> Përfshijnë aftësitë motorike të trupit, të tilla si lëvizja, koordinimi, forca muskulare dhe ndjeshmëria sensoriale.</li>
                        <li><span>Aftësitë sociale:</span> Përfshijnë aftësinë për të komunikuar, ndërlidhur me të tjerët, ndjekur rregulla sociale dhe për të krijuar marrëdhënie të shëndetshme dhe të qëndrueshme me shoqërinë.</li>
                        <li><span>Aftësitë emocionale:</span> Përfshijnë aftësitë për të menaxhuar emocionet dhe për të kuptuar dhe shprehur ndjenjat e tyre në mënyrë të shëndetshme dhe të përshtatshme.</li>
                    </ul>
                </div>
                <div className="content-list-container">
                    <p><span>Funksionimi</span> është mënyra se si një person përdor aftësitë e tij për të kryer detyrat dhe veprimet e përditshme në mjedisin e tij të natyrshëm.</p>
                    <p>Funksionimi është i ndarë në katër kategori:</p>
                    <ul>
                        <li><span>Funksionimi ndërmarrës:</span> Përfshin aftësitë për të kryer de```~tyrat e punës dhe aktiviteteve profesionale.</li>
                        <li><span>Funksionimi fizik:</span> Përfshin aftësitë për të kryer aktivitetet e përditshme fizike, siç janë lëvizja, ushtrimi, higjiena personale dhe shtrëngimi.</li>
                        <li><span>Funksionimi kognitiv:</span> Përfshin aftësinë për të kuptuar, menduar dhe mësuar informacionin dhe për të zgjidhur probleme.</li>
                        <li><span>Funksionimi social:</span> Përfshin aftësitë për të ndërlidhur me të tjerët, për të krijuar marrëdhënie shoqërore dhe për të marrë pjesë në aktivitete sociale.</li>
                    </ul>
                </div>

                <div className="content-paragraph-container">
                    <p>
                        Kur flasim për aftësinë dhe funksionimin e kufizuar, kjo përfshin rrethana kur një individ ka vështirësi në kryerjen e veprimeve dhe detyrave të përcaktuara në mënyrë të pavarur dhe efektive.
                        Kufizimet mund të jenë fizike, kognitive (njohëse), emocionale ose sociale dhe ndikojnë në aftësinë e një personi për të përfshirë veten në jetën e përditshme dhe në shoqëri.
                    </p>
                    <p>
                        KNF është një mjet që ndihmon në vlerësimin dhe përshkrimin e aftësisë dhe funksionimit të individëve me kufizime, dhe siguron një bazë të përbashkët për të kuptuar dhe komunikuar për gjendjen e tyre 
                        të shëndetit në mënyrë objektive.
                    </p>
                </div>

                <div className="content-paragraph-container">
                    <p> 
                    <span className='black-span'>Funksionimi</span> dhe <span className='black-span'>aftësia</span> kuptohen si terme ombrellë që përfaqësojnë aspekte pozitive dhe negative të funksionimit nga perspektiva biologjike, individuale dhe sociale. 
                    Sipas modelit biopsikosocial (për të cilin do të mësojmë në vijim) ato reflektojnë ndërveprimin ndërmjet <span className='black-span'>gjendjes shëndetësore, faktorëve mjedisor dhe faktorëve personal.</span>
                    </p>
                </div>

                <div className="important-paragraph-container">
                    <p>Përshkrimi i funksionimit është i rëndësishëm për identifikimin e statusit shëndetësor dhe nevojave të individëve dhe popullatës.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PershkrimiFunksionimit